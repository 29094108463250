import { Link } from 'gatsby'
import Header from '../components/Header'
import Footer from '../components/Footer'
import banner from '../images/learndetail/banner.png'

const Learndetail: React.FC = () => {
  return (
    <div className="text-base font-regularText">
      <div className="sm:hidden">
        <Header />
      </div>
      <section className="mt-20 h-[268px] sm:h-[238px] w-full bg-[linear-gradient(360deg,#ffffff30_0%,#eaf4fcb5_100%)] shadow-[0_16px_24px_1px_rgba(0,0,0,0.02)] overflow-hidden sm:mt-0 sm:px-4">
        {/* Mobile Page Title */}
        <div className="hidden sm:grid h-30px grid-cols-3 mt-[25px] items-center">
          <Link to="/learn" className="w-6 h-6 text-[#000]">
            <svg
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </Link>
          <div className="text-[22px] leading-[30px] font-bold whitespace-nowrap">
            Academy
          </div>
        </div>
        <div className="mx-auto max-w-7xl md:px-16 sm:p-0">
          <div className="flex mt-[26px] cursor-pointer text-[#828282] sm:hidden">
            <Link to="/">{'Home >'}</Link>
            <Link to="/learn" className="ml-1">
              {'Learn >'}
            </Link>
            <span className="ml-1 text-[#1E94FC]">Academy</span>
          </div>
          <div className="text-[50px] font-boldText font-bold leading-[64px] mt-[30px] sm:mt-8 sm:text-[26px] sm:leading-[34px]">
            What is Web3? Here Are Some Ways To Explain{' '}
            <br className="sm:hidden" /> It To A Friend
          </div>
        </div>
      </section>
      <section className="mt-[47px] mx-auto max-w-7xl md:px-16 sm:px-4 flex sm:flex-col sm:mt-[30px]">
        <div className="flex-1">
          <img src={banner} alt="banner" className="w-full" />
          <p className="text-[40px] leading-[56px] font-boldText font-bold mt-[88px] sm:text-[18px] sm:leading-[32px] sm:mt-[18px]">
            1. Web3 is the new trendy name for the decentralized web.
          </p>
          <div className="text-[#4F4F4F] leading-[22px] mt-[43px] sm:mt-[18px]">
            <div>
              Since 2015, Joseph Lubin, founder and CEO of ConsenSys has been
              giving talks, writing, and supporting teams building Web3 and the
              decentralized web (as we used to describe it in our style guide).
              Web3 is the philosophical touchstone that guided all of ConsenSys’
              early investments and projects. MetaMask is now the primary way in
              which individuals can access the Ethereum blockchain, and many
              more Ethereum-compatible networks. It is a way to generate a
              public-key securely on your phone or desktop, but what it enables
              is a new principle for interacting with the web — one where only
              you have access to your accounts and data and choose what to share
              and what to keep private. Another way to describe MetaMask is that
              it is a cryptographic consent manager.
            </div>
            <div className="mt-8">
              When we referred to the decentralized web, we also referred to the
              rest of the stack beyond decentralized money and identity. Other
              aspects of the decentralized web, such as decentralized storage
              are just becoming essential parts of the stack to persistently
              store files (such as IPFS and Arweave), decentralized storage
              (Golem, W3BCloud and others), and decentralized data (Graph
              Protocol).
            </div>
            <div className="mt-8">
              Now, Web3 is the catch-phrase for an entire investment category of
              a16z and other big venture capital firms, which means it is also
              the subject of both lengthy Twitter threads, irony, derision, and
              confusion. It was only a matter of time until Web3 became a large
              enough part of the public internet discourse that the jesters came
              for the protocol priests.
            </div>
            <div className="flex mt-16 border-[#F2F2F2] border-y pt-[19px] pb-[33px] sm:mt-[72px] sm:pt-[33px] sm:pb-[29px]">
              <div className="flex-1 cursor-pointer sm:cursor-default sm:border-r sm:border-[#E0E0E0]">
                <div className="text-sm text-[#828282] sm:flex sm:items-center sm:justify-center">
                  <svg
                    className="hidden w-5 h5 sm:block text-[#BDBDBD] mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                  Previous post
                </div>
                <div className="mt-2 font-bold font-boldText sm:hidden">
                  When we referred to the decentralized web
                </div>
              </div>
              <div className="flex-1 cursor-pointer sm:cursor-default">
                <div className="text-sm text-[#828282] sm:flex sm:items-center sm:justify-center">
                  Next post
                  <svg
                    className="hidden w-5 h-5 sm:block text-[#BDBDBD] ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
                <div className="mt-2 font-bold font-boldText sm:hidden">
                  When we referred to the decentralized web
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-min w-[353px] ml-[45px] rounded-2xl bg-[linear-gradient(360deg,#FFF_0%,#F3F3F3_100%)] shadow-[0_16px_30px_1px_rgba(0,0,0,0.04)] pt-[42px] pl-[25px] pr-[14px] pb-[40px] sm:ml-0 sm:w-auto sm:mt-[39px]">
          <div>
            Capture the Latest Opportunity: How ViaBTC Capital Picks the
            Promising
          </div>
          <div className="mt-4 text-sm text-[#4F4F4F] border-b pb-5">
            Since 2015, Joseph Lubin, founder and CEO of ConsenSys has been
            giving ...
          </div>
          <div className="mt-[42px]">
            Capture the Latest Opportunity: How ViaBTC Capital Picks the
            Promising
          </div>
          <div className="mt-4 text-sm text-[#4F4F4F] border-b pb-5">
            Since 2015, Joseph Lubin, founder and CEO of ConsenSys has been
            giving ...
          </div>
          <div className="mt-[42px]">
            Capture the Latest Opportunity: How ViaBTC Capital Picks the
            Promising
          </div>
          <div className="mt-4 text-sm text-[#4F4F4F]">
            Since 2015, Joseph Lubin, founder and CEO of ConsenSys has been
            giving ...
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Learndetail
