import { useState } from 'react'
import classnames from 'classnames'
import logo from '../images/common/logo.png'

import twitter from '../images/footer/twitter.png'
import facebook from '../images/footer/facebook.png'
import discord from '../images/footer/discord.png'
import youtube from '../images/footer/youtube.png'
import instagram from '../images/footer/instagram.png'
import telegram from '../images/footer/telegram.png'
const Footer: React.FC<{ className?: string }> = ({ className }) => {
  const [menuList, setMenuList] = useState([
    {
      title: '$CTIO',
      subExpansion: false,
      subList: [
        'Exchange & earn',
        'What is $cito',
        'Creat your token',
        'Rules, privacy',
      ],
    },
    {
      title: 'Learn',
      subExpansion: false,
      subList: ['About', 'Blog', 'FAQs'],
    },
    {
      title: 'Contact us',
      subExpansion: false,
      subList: ['Tel. +41 44 261 13 56', 'mail@aebdibauleitung.ch'],
    },
  ])
  return (
    <footer
      className={classnames('w-full text-sm font-regularText', className)}
    >
      <div className="m-auto max-w-7xl md:px-16 sm:px-5 pt-[110px] pb-12 sm:pt-[56px] sm:pb-[51px]">
        <div className="grid grid-cols-2 sm:grid-cols-1">
          <div>
            <img src={logo} alt="logo" className="w-[167px] h-[45px]" />
            <div className="mt-6 h-[120px] text-[#333] leading-5 font-boldText sm:mt-[26px] sm:font-regularText sm:h-auto">
              Ocean Protocol Foundation Ltd. <br /> The Commerze @ Irving <br />
              1 Irving Place, #08-11 <br /> 369546 Singapore
            </div>
          </div>
          <div className="flex mt-3 sm:mt-[46px] sm:flex-col">
            {menuList.map((x, i) => (
              <div
                key={i}
                className="flex flex-col mr-[100px] last:m-0  sm:m-0 sm:mb-[7px] sm:border-b sm:border-solid sm:border-[#F2F2F2] cursor-pointer"
                onClick={() =>
                  setMenuList(
                    menuList.map(y =>
                      y.title !== x.title
                        ? { ...y, subExpansion: false }
                        : { ...y, subExpansion: !y.subExpansion },
                    ),
                  )
                }
              >
                <div className="text-base font-boldText sm:text-lg sm:h-[50px] sm:flex sm:justify-between sm:items-center sm:font-regularText">
                  {x.title}
                  <svg
                    className={`hidden sm:block w-6 h-6 text-[#BDBDBD] font-regularText transition-all duration-300 ${
                      x.subExpansion ? 'rotate-90' : ''
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
                <ul
                  className={`mt-2 text-[#333] sm:mt-0 sm:text-base sm:mb-2 last:sm:mb-4 sm:overflow-hidden transition-all duration-300 ${
                    x.subExpansion ? 'max-h-[350px]' : 'max-h-0'
                  }`}
                >
                  {x.subList?.map((y, j) => (
                    <li key={j} className="mt-2">
                      {y}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-6 w-[511px] h-[66px] rounded-[33px] shadow-[0_16px_24px_1px_rgba(0,0,0,0.04)] bg-white overflow-hidden relative border border-[#E0E0E0] border-solid sm:mt-[45px] sm:w-full">
          <input
            type="text"
            name="email"
            placeholder="Email address"
            className="w-full h-full py-[23px] pl-6 outline-none border-none text-[#BDBDBD] rounded"
          />
          <button className="absolute right-2 top-2 w-[112px] h-[50px] px-4 py-[15px] bg-[linear-gradient(270deg,#1E94FC_0%,#AED8FF_100%)] rounded-[60px] text-white">
            Sign Me Up
          </button>
        </div>
        <div className="flex mt-6 sm:mt-[30px] sm:justify-between">
          {[twitter, facebook, discord, youtube, instagram, telegram].map(
            (x, j) => (
              <img
                key={j}
                src={x}
                alt="twitter"
                className="w-[34px] h-[34px] mr-[14px] cursor-pointer transition-all hover:scale-150 sm:m-0"
              />
            ),
          )}
        </div>
      </div>
      <div className="bg-[#1E94FC] h-12 sm:h-[50px]">
        <div className="flex items-center justify-between h-full m-auto max-w-7xl md:px-16 sm:px-[18px] text-xs text-[#97CBFA] sm:justify-center">
          <div className="sm:hidden">
            COPYRIGHT CHAINTOKENOMICS. ALL RIGHTS RESERVED
          </div>
          <div className="sm:hidden">Privacy policy Site policy Site map</div>
          <div className="hidden sm:block">
            COPYRIGHT CHAINTOKENOMICS. ALL RIGHTS RESERVED
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
